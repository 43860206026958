<template>
  <div class="verify-phone-contaner">
    <p class="title">修改绑定手机</p>
    <!-- 步骤条 s -->
    <div class="step">
      <div class="line1"></div>
      <el-steps :active="step" :space="188" align-center>
        <el-step title="验证身份"></el-step>
        <el-step title="绑定新手机"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <div class="line2"></div>
    </div>
    <!-- 步骤条 e -->
    <!-- 步骤二表单 s -->
    <div class="form-box2" v-if="step == 2">
      <el-form
        label-width="130px"
        :model="ruleForm2"
        :status-icon="false"
        :rules="rules2"
        ref="ruleForm2"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <!-- 请输入新手机： -->
        <el-form-item label="请输入新手机:" required prop="code">
          <el-input
            type="phone"
            maxlength="20"
            v-model="ruleForm2.code"
            placeholder="请输入新手机"
            autocomplete="off"
          ></el-input>
          <button class="getCode f14" @click="getCode2">获取验证码</button>
        </el-form-item>
        <!-- 请输入新手机：结束 -->
        <!-- 请输入验证码： -->
        <el-form-item label="请输入验证码:" required prop="num">
          <el-input
            type="number"
            maxlength="20"
            v-model="ruleForm2.num"
            placeholder="请输入验证码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 请输入验证码：结束 -->
        <!-- 提交开始 -->
        <el-form-item>
          <el-button class="btn cf f16" @click="submitForm2('ruleForm2')"
            >提交</el-button
          >
        </el-form-item>
        <!-- 提交结束 -->
      </el-form>
    </div>
    <!-- 步骤一表单 e -->
  </div>
</template>

<script>
export default {
  name: "YoufujichuangVerifyPhone",

  data() {
    var validateCode2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      }
      callback();
    };
    var validateNum2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      }
      callback();
    };
    return {
      step: 2, //激活步骤条
      ruleForm2: {
        code: "", //新手机
        num: "", //验证码
      },
      rules2: {
        code: [{ validator: validateCode2, trigger: "blur" }],
        num: [{ validator: validateNum2, trigger: "blur" }],
      },
    };
  },

  mounted() {},

  methods: {
    // 获取验证码
    getCode2() {},
    // 提交 步骤二
    submitForm2(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.verify-phone-contaner {
  padding: 32px;
  .title {
    padding-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
  }
  .step {
    .line1 {
      display: inline-block;
      width: 174px;
      height: 8px;
      background: #ff0015;
      border-radius: 90px 90px 90px 90px;
      position: relative;
      top: 22px;
      left: 84px;
    }
    .line2 {
      display: inline-block;
      width: 174px;
      height: 8px;
      background: #dcdcdc;
      border-radius: 90px 90px 90px 90px;
      position: relative;
      top: -51px;
      left: 654px;
    }
    margin: 0 auto;
    margin-top: 30px;
    .el-steps {
      display: flex;
      justify-content: center;
    }
    .is-finish {
      color: #ff0015;
      .el-step__icon {
        border-color: #ff0015;
        background-color: #ff0015;
        color: #ffffff;
      }
      .el-step__line-inner {
        height: 8px;
        background: #ff0015;
        border-radius: 90px 90px 90px 90px;
        border-color: #ff0015;
      }
    }
    .el-step__line {
      height: 8px;
      background-color: #dcdcdc;
    }
    .is-process {
      color: #dcdcdc;
      .el-step__icon {
        border-color: #dcdcdc;
        background-color: #dcdcdc;
        color: #ffffff;
      }
      .el-step__line {
        background-color: #dcdcdc;
      }
    }
    .is-wait {
      color: #dcdcdc;
      .el-step__icon {
        border-color: #dcdcdc;
        background-color: #dcdcdc;
        color: #ffffff;
      }
      .el-step__line {
        background-color: #dcdcdc;
      }
    }
  }
  //   表单
  .form-box {
    margin-top: 95px;
    margin-left: 103px;
    // 性別
    .item {
      display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .item2 {
      margin-left: 56px;
    }
    .el-form-item__content {
      height: 40px !important;
    }
    //input表单样式
    .el-input__inner {
      width: 320px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      margin-bottom: 32px;
      display: inline-block;
    }
    //label样式
    .el-form-item__label {
      text-align: right;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 400px;
      height: 120px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      margin-top: 167px;
      margin-left: 64px;
    }
    .getCode {
      width: 112px;
      height: 40px;
      background: #ff0015;
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      position: relative;
      top: -41px;
      left: 339px;
    }
  }
  //   表单
  .form-box2 {
    margin-top: 95px;
    margin-left: 103px;
    // 性別
    .item {
      display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .item2 {
      margin-left: 56px;
    }
    .el-form-item__content {
      height: 40px !important;
    }
    //input表单样式
    .el-input__inner {
      width: 320px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      margin-bottom: 32px;
      display: inline-block;
    }
    //label样式
    .el-form-item__label {
      text-align: right;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 400px;
      height: 120px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      margin-top: 167px;
      margin-left: 64px;
    }
    .getCode {
      width: 112px;
      height: 40px;
      background: #ff0015;
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      position: relative;
      top: -41px;
      left: 339px;
    }
  }
}
</style>